import { SwitchableTabType } from 'src/lib/types/random'
import { CustomizeUIStateType, TrustBadgesConfigType } from 'src/lib/types/customizeUI'
import { RadioItemConfigType } from 'src/lib/types/discounts'

export const customizeUITabList: SwitchableTabType[] = [
  {
    isActive: true,
    label: 'Elements',
    name: 'ELEMENTS',
    childComponent: 'ElementsTab'
  },
  {
    isActive: false,
    label: 'Brand',
    name: 'BRAND',
    childComponent: 'BrandTab'
  },
  {
    isActive: false,
    label: 'Login',
    name: 'LOGIN',
    childComponent: 'LoginTab'
  },
  {
    isActive: false,
    label: 'Coupons',
    name: 'COUPONS',
    childComponent: 'CouponsTab'
  },
  {
    isActive: false,
    label: 'Address',
    name: 'ADDRESS',
    childComponent: 'AddressTab'
  },
  {
    isActive: false,
    label: 'Payment',
    name: 'PAYMENT',
    childComponent: 'PaymentsTab'
  }
]

export const defaultTrustBadgesConfig: TrustBadgesConfigType = {
  showBadges: false,
  badges: [
    {
      text: '',
      label: 'star',
      weight: 0,
      imgPath: '/images/badges/star.svg',
      isActive: false
    },
    {
      text: '',
      label: 'smile',
      weight: 0,
      imgPath: '/images/badges/smile.svg',
      isActive: false
    },
    {
      text: '',
      label: 'lock',
      weight: 0,
      imgPath: '/images/badges/lock.svg',
      isActive: false
    },
    {
      text: '',
      label: 'truck',
      weight: 0,
      imgPath: '/images/badges/truck.svg',
      isActive: false
    },
    {
      label: 'globe',
      imgPath: '/images/badges/globe.svg',
      isActive: false
    },
    {
      label: 'return',
      imgPath: '/images/badges/return.svg',
      isActive: false
    },
    {
      label: 'clock',
      imgPath: '/images/badges/clock.svg',
      isActive: false
    }
  ]
}

export const defaultCustomizeUIState: CustomizeUIStateType = {
  tabList: customizeUITabList,
  brandLogoURL: '',
  colorPalette: {
    primaryColor: '#fff',
    primaryTextColor: '#000'
  },
  bannerConfigs: {
    loginBanner: {
      color: '',
      textColor: '',
      text: '',
      enabled: false
    },
    addressBanner: {
      color: '',
      textColor: '',
      text: '',
      enabled: false
    },
    paymentBanner: {
      color: '',
      textColor: '',
      text: '',
      enabled: false
    }
  },
  buttonCTATexts: {
    loginButtonCTAText: '',
    addressButtonCTAText: ''
  },
  couponVisibilityConfig: {
    loginCouponVisibility: {
      areCouponsVisible: true,
      visibilityExtent: 'codeEntry'
    },
    addressCouponVisibility: {
      areCouponsVisible: true,
      visibilityExtent: 'codeEntry'
    },
    paymentsCouponVisibility: {
      areCouponsVisible: true,
      visibilityExtent: 'codeEntry'
    }
  },
  deliveryInfoConfig: {
    isVisible: false,
    deliveryInfoText: '',
    isETDEnabled: false
  },
  paymentMethodsConfig: [],
  couponEditorConfig: [],
  trustBadgesConfig: defaultTrustBadgesConfig,
  orderSummaryConfig: {
    isOrderSummaryOpen: false,
    isPriceStrikeThroughEnabled: false,
    isQuantityEditable: false,
    isOnePageCheckoutEnable: false,
    showOrderSummaryAs: 'TILE'
  }
}

export const couponVisibilityEditorRadioItemsConfig = [
  {
    label: 'As coupon cards',
    valueType: 'cards'
  },
  {
    label: 'As code entry field',
    valueType: 'codeEntry'
  }
]

export enum OrderSummaryVisibilityType {
  TILE = 'TILE',
  HEADER = 'HEADER'
}
