import React, { createContext, useState } from 'react'
import { OnboardingContextDataType } from '../types/onboarding'
import { parseOnboardingData } from '../utils/parsers'
//import { parseOnboardingData } from '../utils/parsers'

interface OnboardingContextType {
  setOnboardingData: (data: any, parse?: boolean) => void
  onboarding: OnboardingContextDataType | undefined
  activeShopId: string
  setActiveShop: (id: string) => void
}

const OnboardingContext = createContext({} as OnboardingContextType)

const OnboardingProvider = (props: any) => {
  const [activeShopId, setActiveShopId] = useState<string>('')
  const [onboarding, setOnboarding] = useState<OnboardingContextDataType>()

  const setOnboardingData = (data: any, parse?: boolean) => {
    if (Boolean(parse)) {
      setOnboarding(data)
      return
    }
    setOnboarding(parseOnboardingData(data))
  }
  const setActiveShop = (id: string) => {
    setActiveShopId(id)
  }

  const onboardingContextValue: OnboardingContextType = {
    setOnboardingData,
    onboarding,
    activeShopId,
    setActiveShop
  }

  return <OnboardingContext.Provider value={onboardingContextValue} {...props} />
}

const useOnboarding = () => React.useContext(OnboardingContext)

export { OnboardingProvider, useOnboarding }
