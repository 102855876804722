//General constants
export const constants = {
  AUTH_COOKIE_CLIENT: 'merchant_dash_auth',
  REFRESH_TOKEN_COOKIE_CLIENT: 'merchant_dash_refresh',
  AUTH_TOKEN_HEADER_SERVER: 'Authorization',
  AUTH_COOKIE_CLIENT_EXPIRY: 1 / 24,
  REFRESH_TOKEN_COOKIE_CLIENT_EXPIRY: 365,
  FLO_USER_COOKIE_EXPIRY: 1000,
  ENV_PROD: 'production',
  ENV_DEV: 'development',
  FLO_FILTERS: 'flo_filters',
  FLO_USER_DATE: 'flo_user_data',
  FLO_MERCHANT_DATA: 'flo_merchant_data',
  FLO_SHIPPING_NOTIFICATION_COOKIE: 'flo_shipping_notification',
  FLO_SHOPFLO_VERSION: 'X-Shopflo-Version'
}

//API Uri's constants
export const apiURI = {
  FLO_CHECKOUT: process.env.NEXT_PUBLIC_APP_API_URI,
  FLO_KRATOS_CHECKOUT: process.env.NEXT_PUBLIC_APP_KRATOS_API_URI,
  PUBLIC_FLO_CHECKOUT: process.env.NEXT_PUBLIC_APP_API_PUBLIC_URI,
  CHECKOUT_NODE: process.env.NEXT_PUBLIC_APP_ANALYTICS_API_URI,
  DISCOUNT_ENGINE: process.env.NEXT_PUBLIC_APP_DE_API_URI,
  CATALOGUE_ENGINE: process.env.NEXT_PUBLIC_APP_CE_API_URI,
  PINCODE_ENGINE: process.env.NEXT_PUBLIC_APP_PE_API_URI,
  AUTH_ENGINE: process.env.NEXT_PUBLIC_APP_AE_API_URI,
  REWARDS_ENGINE: process.env.NEXT_PUBLIC_APP_RE_API_URI,
  SHIPPING_ENGINE: process.env.NEXT_PUBLIC_APP_SE_API_URI,
  SHOPIFY_CONNECTOR: process.env.NEXT_PUBLIC_APP_SHOPIFY_CONNECTOR_API_URI,
  FMS_ENGINE: process.env.NEXT_PUBLIC_APP_FMS_API_URI,
  SETTLEMENT_ENGINE: process.env.NEXT_PUBLIC_APP_FE_API_URI
}

//Dashboard tabs constants
export const dashboardTabs = {
  FLO_ORDERS_TAB: 'orders',
  FLO_ANALYTICS_TAB: 'analytics',
  FLO_CHECKOUT_TAB: 'abandonedCarts',
  FLO_REWARDS_ANALYTICS_TAB: 'rewards',
  FLO_UPSELL_ANALYTICS_TAB: 'upsell',
  FLO_INSIGHTS_ANALYTICS_TAB: 'insights'
}

//Order's table constants
export const orderTableConstants = {
  PAYMENT_MODE_PREPAID: 'PREPAID',
  PAYMENT_MODE_COD: 'COD',
  PAYMENT_MODE_SPLIT_COD: 'SPLIT COD',
  REFUND_STATUS_REFUNDED: 'Refunded',
  REFUND_ACTION_ISSUE: 'Issue',
  REFUND_STATUS_NOT_APPICABLE: 'Not Applicable',
  CANCELLATION_STATUS_CANCELLED: 'Cancelled',
  CANCELLATION_ACTION_CANCEL: 'Cancel',
  NOT_APPLICABLE: 'N.A.',
  DASH: '-',
  ZERO: '0'
}

//Analytic's constants
export const analyticsConstants = {
  FLO_ORDERS: 'shopflo_orders',
  FLO_ORDERS_SALES: 'shopflo_orders_sales',
  FLO_ORDERS_COUNT: 'shopflo_orders_count',
  FLO_CONVERSION_FUNNEL: 'conversion_funnel',
  FLO_SALES_WIDGET: 'utm_sales_widget',
  FLO_COD_TO_PREPAID: 'cod_to_prepaid',
  FLO_SUCCESS_RATE: 'payment_success_rate',
  FLO_CONVERSION_CHART: 'conversion_chart',
  FLO_PAYMENT_SPLIT: 'payment_split',
  FLO_CONVERSION_REPORT: 'conversion_over_time_table',
  FLO_UTM_FIRST_CLICK_SALES_WIDGET: 'utm_first_click',
  FLO_PREPAID_SHARE: 'prepaid_share',
  FLO_DISCOUNT_PERFORMANCE: 'discount_performance',
  FLO_CONVERSIONS_WITH_DISCOUNTS: 'conversion_with_discounts',
  FLO_CONVERSIONS_WITHOUT_DISCOUNTS: 'conversion_without_discounts',
  FLO_DISCOUNT_CONVERSIONS: 'discount_conversions',
  FLO_DISCOUNT_FAILURE_RATES: 'failed_discount_app',
  FLO_PAYMENT_SPLIT_REPORT: 'payment_split_with_timescale',
  FLO_PREFILLED_ADDRESS_WIDGET: 'prefilled_address',
  FLO_RECOVERED_CHECKOUTS: 'recovered_checkouts',
  FLO_ACR_SURVEY_DATA: 'acr_survey_data',
  FLO_ACR_SURVEY_DATA_ALL: 'acr_survey_data_all',

  FLO_CONVERSION_UNIT_COUNT: 'count',
  FLO_CONVERSION_UNIT_PERCENTAGE: 'percentage'
}

//Discount table constants
export const discountTableConstants = {
  DISCOUNT_TAG_SHOPFLO: 'SHOPFLO',
  DISCOUNT_TAG_IMPORTED: 'IMPORTED'
}

export const discountConstants = {
  CATALOGUE_COLLECTION: 'COLLECTION',
  CATALOGUE_PRODUCT: 'PRODUCT',
  CATALOGUE_VARIANT: 'VARIANT',
  CATALOGUE_PRODUCT_AND_VARIANT: 'PRODUCT_AND_VARIANT'
}

export const alertSeverites = {
  SEVERITY_SUCCESS: 'success',
  SEVERITY_ERROR: 'error',
  SEVERITY_WARNING: 'warning',
  SEVERITY_INFO: 'info'
}

export const permissionType = {
  CREATE_DISCOUNTS: 'CREATE_DISCOUNTS',
  BULK_CREATE_DISCOUNTS: 'BULK_CREATE_DISCOUNTS',
  IMPORT_DISCOUNT: 'IMPORT_DISCOUNT',
  BUNDLE_DISCOUNTS: 'BUNDLE_DISCOUNTS',
  GROUP_DISCOUNTS: 'GROUP_DISCOUNTS',
  DISCOUNT_EDIT_DESCRIPTION: 'DISCOUNT_EDIT_DESCRIPTION',
  BANNER_CTA_TEXT: 'BANNER_CTA_TEXT',
  PAYMENT_TITLE_REORDER: 'PAYMENT_TITLE_REORDER',
  CUSTOMIZE_CHECKOUT_UI: 'CUSTOMIZE_CHECKOUT_UI',
  CUSTOMIZE_COUPONS: 'CUSTOMIZE_COUPONS',
  COUPON_ORDERING: 'COUPON_ORDERING',
  COUPON_TEXT_CUSTOMISE: 'COUPON_TEXT_CUSTOMISE',
  PAYMENT_SETTINGS: 'PAYMENT_SETTINGS',
  PG_SUPPORT: 'PG_SUPPORT',
  MULTI_PG_SUPPORT: 'MULTI_PG_SUPPORT',
  PAYMENT_TILE_REORDERING: 'PAYMENT_TILE_REORDERING',
  ADD_MEMBER: 'ADD_MEMBER',
  ADD_CUSTOM_ROLE: 'ADD_CUSTOM_ROLE',
  RATE_LEVEL_ETD_COD: 'RATE_LEVEL_ETD_COD',
  SF_DELIVERY_GROUPS: 'SF_DELIVERY_GROUPS',
  ACR_EVENT_WEBHOOK: 'ACR_EVENT_WEBHOOK',
  ACTIVATE_REWARDS: 'ACTIVATE_REWARDS',
  TAB_REWARDS: 'TAB_REWARDS',
  DISCOUNT_PERFORMANCE: 'DISCOUNT_PERFORMANCE',
  ITEM_OFFER_DISCOUNTS: 'ITEM_OFFER_DISCOUNTS',
  CHANGE_COUPON_DESCRIPTION: 'CHANGE_COUPON_DESCRIPTION',
  ZONE_LEVEL_COD: 'ZONE_LEVEL_COD',
  ZONE_LEVEL_ETD: 'ZONE_LEVEL_ETD',
  CONFIGURE_ZONE_RATE: 'CONFIGURE_ZONE_RATE',
  CUSTOMIZE_PAYMENT_METHODS: 'CUSTOMIZE_PAYMENT_METHODS',
  CUSTOMIZE_COUPONS_ADDRESS: 'CUSTOMISE_COUPONS_ADDRESS',
  CUSTOMIZE_COUPONS_LOGIN: 'CUSTOMISE_COUPONS_LOGIN',
  CUSTOMIZE_COUPONS_PAYMENT: 'CUSTOMISE_COUPONS_PAYMENT',
  CHANNEL: 'CHANNEL',
  TAB_OVERVIEW: 'TAB_OVERVIEW',
  TAB_SHIPPING_DELIVERY_V2: 'TAB_SHIPPING_DELIVERY_V2',
  TAB_DISCOUNTS: 'TAB_DISCOUNTS',
  COUPON_REORDERING: 'COUPON_REORDERING',
  TRUST_BADGES: 'TRUST_BADGES',
  CUSTOMIZE_BANNER_LOGIN: 'CUSTOMIZE_BANNER_LOGIN',
  CUSTOMIZE_BANNER_ADDRESS: 'CUSTOMIZE_BANNER_ADDRESS',
  CUSTOMIZE_BANNER_PAYMENT: 'CUSTOMIZE_BANNER_PAYMENT',
  SPLIT_COD: 'SPLIT_COD',
  AUTO_PG_ROUTING: 'AUTO_PG_ROUTING',
  REFUND_ORDERS: 'REFUND_ORDERS',
  CANCEL_ORDERS: 'CANCEL_ORDERS',
  TAB_CART: 'TAB_CART',
  PLACE_BY_ACR: 'PLACE_BY_ACR',
  DISCOUNT_AI: 'DISCOUNT_AI'
}

export const permissionsConfig = []

export enum analyticsApiRequestType {
  GET = 'GET',
  POST = 'POST'
}
