import { GenericAnalyticsWidgetType } from 'src/lib/types/analytics'
import { CreditNameType, EarnMessageType } from 'src/lib/types/rewards'
import { RewardAnalyticsWidgetType } from 'src/lib/types/rewards'

export const initialRewardsState = {}

export const rewardsPageTabList = [
  {
    isActive: true,
    label: 'Setup',
    name: 'setup',
    childComponent: 'RewardsSetupTab'
  },
  {
    isActive: false,
    label: 'Customisation',
    name: 'customisation',
    childComponent: 'RewardsCustomisationTab'
  },
  {
    isActive: false,
    label: 'Analytics',
    name: 'analytics',
    childComponent: 'RewardsAnalyticsTab'
  }
]
export const defaultCreditName: CreditNameType = {
  singularName: 'Store Credit',
  pluralName: 'Store Credits'
}

export const defaultEarnMessage: EarnMessageType = {
  enable: false,
  line_1: 'Earn <Rewards_value> cashback with this purchase',
  line_2: '<Rupees_value> off on your next order'
}

export const rewardsAnalyticsWidgetsConfig: RewardAnalyticsWidgetType[] = [
  {
    id: 'reward_earn',
    title: 'Earn',
    positionX: 0,
    positionY: 1,
    primaryMetric: {
      value: 0,
      title: 'Total credit issued',
      type: 'AMOUNT'
    },
    detailedMetricsList: [
      [
        {
          title: 'Earn to GMV ratio',
          value: 0,
          type: 'PERCENTAGE'
        }
      ],
      [
        {
          title: 'Cashback awarded',
          value: 0,
          type: 'AMOUNT',
          description: '0 instances'
        },
        {
          title: 'Manual top-up',
          value: 0,
          type: 'AMOUNT',
          description: '0 instances'
        }
      ]
    ]
  },
  {
    id: 'rewards_manual_top_up_reasons',
    title: 'Reasons for manual top-up',
    positionX: 0,
    positionY: 0,
    detailedMetricsList: []
  },
  {
    id: 'reward_burn',
    title: 'Burn',
    positionX: 1,
    positionY: 0,
    primaryMetric: {
      value: 0,
      title: 'Total credit burned',
      type: 'AMOUNT'
    },
    detailedMetricsList: [
      [
        {
          title: 'Sales with redemption',
          value: 0,
          type: 'AMOUNT'
        },
        {
          title: 'Burn to GMV ratio',
          value: 0,
          type: 'PERCENTAGE'
        }
      ],
      [
        {
          title: 'Cashback burned',
          value: 0,
          type: 'AMOUNT',
          description: '0 times'
        },
        {
          title: 'Manual burn',
          value: 0,
          type: 'AMOUNT',
          description: 'x 1'
        }
      ]
    ]
  },
  {
    id: 'rewards_expiry',
    title: 'Expiry',
    positionX: 1,
    positionY: 1,
    primaryMetric: {
      value: 0,
      title: 'Expiring this month',
      type: 'AMOUNT'
    }
  },
  {
    id: 'rewards_customers',
    title: 'Customers',
    positionX: 2,
    positionY: 0,
    secondaryMetricsList: [
      {
        title: 'Earned credits',
        value: 0,
        type: 'NUMBER'
      },
      {
        title: 'Burned credits',
        value: 0,
        type: 'NUMBER'
      },
      {
        title: 'Repeat frequency',
        value: '0 days',
        type: 'TIME'
      }
    ],
    detailedMetricsList: [
      [
        {
          title: 'No burn',
          value: 0,
          type: 'AMOUNT',
          description: '0 instances'
        },
        {
          title: '1-2 burns',
          value: 0,
          type: 'AMOUNT',
          description: '0 instances'
        },
        {
          title: 'More than 2 burns',
          value: 0,
          type: 'AMOUNT',
          description: '0 instances'
        }
      ]
    ]
  }
]
