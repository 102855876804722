/* eslint-disable no-var */
/* eslint-disable prefer-rest-params */
// @ts-nocheck

function clarity() {
  var params = Array.prototype.slice.call(arguments)
  if (!window.clarity) {
    throw new Error('Clarity is not initialized')
  }

  window.clarity.apply(undefined, params)
}

function hasStarted() {
  return typeof window.clarity === 'function'
}

function identify(userId, properties) {
  clarity('identify', userId, properties)
}

function consent() {
  clarity('consent')
}

function setTag(key, value) {
  clarity('set', key, value)
}

function upgrade(reason) {
  clarity('upgrade', reason)
}

export default { hasStarted, identify, consent, setTag, upgrade } as clarity
