import React, { createContext, useState } from 'react'
import { LoaderType } from '../types/loader'

interface LoaderContextType {
  setLoaderProperties: (loaderConfig: LoaderType) => void
  loaderConfig: LoaderType
}

const LoaderContext = createContext({} as LoaderContextType)

const initialState: LoaderType = {
  isLoaderVisible: false,
  message: 'Saving...',
  isCancellable: false
}

const LoaderProvider = (props: any) => {
  const [loaderConfig, setLoaderConfig] = useState<LoaderType>(initialState)

  const setLoaderProperties = (loaderConfig: LoaderType) => {
    setLoaderConfig(loaderConfig)
  }

  const loaderContextValue: LoaderContextType = {
    setLoaderProperties,
    loaderConfig
  }

  return <LoaderContext.Provider value={loaderContextValue} {...props} />
}

const useLoader = () => React.useContext(LoaderContext)

export { LoaderProvider, useLoader }
