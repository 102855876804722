import React, { useEffect, useState, useLayoutEffect, useRef } from 'react'
import { Bell } from 'react-feather'

const appID = process.env.NEXT_PUBLIC_CANNY_APP_ID
const NewsWidget: React.FC<{}> = ({}) => {
  useLayoutEffect(() => {
    ;(window as any).Canny('initChangelog', {
      appID: appID,
      position: 'bottom',
      align: 'right',
      theme: 'light'
    })
  }, [])

  return (
    <div className='relative mr-4 cursor-pointer'>
      <div data-canny-changelog>
        <Bell color={`#949494`} size={20} />
      </div>
    </div>
  )
}

export default NewsWidget
