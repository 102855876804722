import toast from 'react-hot-toast'

export const successToast = (msg: string, duration = 3000) => {
  toast.dismiss()
  toast.success(msg, {
    duration: duration,
    position: 'bottom-center',
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff'
    }
  })
}

export const errorToast = (msg = 'Error. Please try later.', duration = 3000) => {
  toast.dismiss()
  toast.error(msg, {
    duration: duration,
    position: 'bottom-center',
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff'
    }
  })
}
export const infoToast = (msg: string, duration = 3000) => {
  toast.dismiss()
  toast(msg, {
    icon: 'ⓘ',
    duration: duration,
    position: 'bottom-center',
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff',
      fontSize: '12px'
    }
  })
}
