import Cookies from 'js-cookie'
import { constants } from './constants'
import { postRequest } from 'src/lib/core/api-client'
import { apiURI } from 'src/lib/utils/constants'
import axios from 'axios'
import { getRequestParams, getResponseParams } from './responseAndRequestHandlers'
import { newRelicExceptionReport } from '../third-party/newrelic'

export const setAuthCookies = (idToken: string, refreshToken: string, authExpiry?: number, refreshExpiry?: number) => {
  const authExpiresDate = authExpiry && new Date(authExpiry)
  const refreshExpiresDate = refreshExpiry && new Date(refreshExpiry)

  try {
    Cookies.set(constants.AUTH_COOKIE_CLIENT, idToken, {
      expires: authExpiresDate ?? constants.AUTH_COOKIE_CLIENT_EXPIRY,
      sameSite: 'none',
      secure: true
    })

    if (Boolean(refreshToken)) {
      Cookies.set(constants.REFRESH_TOKEN_COOKIE_CLIENT, refreshToken, {
        expires: refreshExpiresDate ?? constants.REFRESH_TOKEN_COOKIE_CLIENT_EXPIRY,
        sameSite: 'none',
        secure: true
      })
    } else {
      Cookies.set(constants.REFRESH_TOKEN_COOKIE_CLIENT, refreshToken, {
        expires: new Date(0),
        sameSite: 'none',
        secure: true
      })
      Cookies.remove(constants.REFRESH_TOKEN_COOKIE_CLIENT)
    }
  } catch (e: any) {
    console.error(e)
    throw new Error(e)
  }
}

export const refreshExpiredIdToken = async (isForceRefresh?: boolean) => {
  const refreshToken = Cookies.get(constants.REFRESH_TOKEN_COOKIE_CLIENT)
  if (!Boolean(isForceRefresh) && Cookies.get(constants.AUTH_COOKIE_CLIENT)) return true
  if (!Boolean(refreshToken)) return false

  try {
    const payload: any = {
      refresh_token: refreshToken
    }

    const { headers } = getRequestParams(false, apiURI.AUTH_ENGINE)
    const response = await axios.post(`${apiURI.AUTH_ENGINE}/merchant-user/refresh`, payload, { headers: headers })
    const adaptedResponse: any = getResponseParams(apiURI.AUTH_ENGINE, response)

    if (!adaptedResponse) return false

    const {
      access_token: newAuthToken,
      refresh_token: newRefreshToken,
      access_token_expires_at: newAuthTokenExpiry,
      refresh_token_expires_at: newRefreshTokenExpiry
    } = adaptedResponse?.data?.data || {}

    setAuthCookies(newAuthToken, newRefreshToken, newAuthTokenExpiry, newRefreshTokenExpiry)
    return true
  } catch (e: any) {
    newRelicExceptionReport(e, 'refreshExpiredIdToken', `${apiURI.AUTH_ENGINE}/merchant-user/refresh`, {
      refresh_token: refreshToken
    })
    console.error(e)
    Cookies.remove(constants.REFRESH_TOKEN_COOKIE_CLIENT)
    return false
  }
}
