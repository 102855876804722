// ** React Imports
import React from 'react'

import { useLoader } from 'src/lib/contexts/LoaderContext'
import ComponentLoader from './ComponentLoader'

export const GenericLoader: React.FC<{}> = () => {
  const { loaderConfig } = useLoader()
  return (
    <ComponentLoader
      isLoaderVisible={loaderConfig?.isLoaderVisible}
      message={loaderConfig?.message}
      isCancellable={loaderConfig?.isCancellable}
      isComponent={loaderConfig?.isComponent}
      customClass={loaderConfig?.customClass}
    />
  )
}
