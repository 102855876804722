import { useState, useEffect, ReactNode } from 'react'
import { useRouter } from 'next/router'

import { hasAuthCookie, hasRefreshCookie } from 'src/lib/utils/helpers'
import { refreshExpiredIdToken } from 'src/lib/utils/auth'
import { useUser } from 'src/lib/contexts/UserContext'

export const RouteGuard = ({ children }: any) => {
  const router = useRouter()
  const [authorized, setAuthorized] = useState(false)

  const { user } = useUser()

  useEffect(() => {
    authCheck(router.asPath)

    // on route change start - hide page content by setting authorized to false
    const hideContent = () => setAuthorized(false)
    router.events.on('routeChangeStart', hideContent)

    // on route change complete - run auth check
    router.events.on('routeChangeComplete', authCheck)

    // unsubscribe from events in useEffect return function
    return () => {
      router.events.off('routeChangeStart', hideContent)
      router.events.off('routeChangeComplete', authCheck)
    }
  }, [router, user])

  const authCheck = async (url: string) => {
    // redirect to login page if accessing a private page and not logged in
    if (!hasAuthCookie()) {
      if (hasRefreshCookie()) {
        await refreshExpiredIdToken()
        setAuthorized(true)
      } else {
        setAuthorized(false)
        // router.replace('/login')
      }
    } else {
      setAuthorized(true)
      if (!user) return
    }
  }

  return authorized && children
}
