/**
 * Method does the newRelic browser API calls to set version info and custom attributes (user info)
 * @param  {error} err                          The error object
 * @param  {string} errorCapturedAt             The method/function where the error was captured
 * @param  {string} apiEndPoint                 The API Endpoint where error was captured
 * @param  {object} requestPayload (optional)   The payload of the request
 */
export const initiateNewRelic = (userData: any) => {
  if (userData && typeof newrelic !== 'undefined') {
    Object.keys(userData).map((key: string) => {
      newrelic.setCustomAttribute(key, userData[key])
    })
  }
}

/**
 * Method captures exceptions and sends them to NewRelic. Exceptions can be seen on NewRelic dashboard.
 * @param  {any} exception                          The error object
 * @param  {string} errorCapturedAt                 The method/function where the error was captured
 * @param  {string} apiEndPoint                     The API Endpoint where error was captured
 * @param  {any} requestPayload (optional)          The payload of the request
 * @param  {any} exceptionMetadata (optional)       Extra data will be added to this parameter
 */
export const newRelicExceptionReport = (
  exception: any,
  errorCapturedAt: string,
  apiEndPoint: string,
  requestPayload: any = {},
  exceptionMetadata: any = {}
) => {
  if (!errorCapturedAt || !exception || !(process.env.NEXT_PUBLIC_APP_NODE_ENV === 'production')) return

  const metadata = {
    ...exceptionMetadata,
    timestamp: new Date()
  }

  const error = new Error(exception)
  const newRelicErrorAttributes = {
    error_source: errorCapturedAt,
    end_point: apiEndPoint,
    request_payload: requestPayload,
    metadata: metadata
  }
  if (typeof newrelic !== 'undefined') newrelic.noticeError(error, newRelicErrorAttributes)
}
