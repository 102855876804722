// ** React Imports
import React from 'react'

// ** MUI Imports
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress'
import { Backdrop } from '@mui/material'
import { LoaderType } from 'src/lib/types/loader'

const ComponentLoader: React.FC<LoaderType> = ({
  isLoaderVisible,
  message,
  isCancellable,
  isComponent,
  customClass
}) => {
  return (
    <>
      {isLoaderVisible && (
        <>
          <Backdrop
            className={`text-white flex justify-center items-center z-[9999] ${customClass} ${
              Boolean(isComponent) ? '!bg-[#00000031]' : '!bg-[#00000099]'
            }`}
            sx={{ position: Boolean(isComponent) ? 'absolute' : 'fixed' }}
            open={true}
          >
            <div className='flex flex-col items-center justify-center space-y-4'>
              <CircularProgress
                variant='indeterminate'
                disableShrink
                color='inherit'
                sx={{
                  animationDuration: '500ms',
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round'
                  }
                }}
                size={40}
                thickness={4}
              />
              <p className=''>{message && message !== '' ? message : 'Saving...'}</p>
              {isCancellable && <button className='px-2 py-1 border border-white rounded-lg'>Cancel</button>}
            </div>
          </Backdrop>
          {Boolean(isComponent) && (
            <div className={`bg-white absolute top-0 left-0 w-full h-full ${customClass} z-[9998]`} />
          )}
        </>
      )}
    </>
  )
}

export default ComponentLoader
