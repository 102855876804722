/**
 * Method to check if the passed email address is valid
 * @param {string} email                Email ID to validate
 *
 * @return {boolean} true/false         Returns true if the email is valid, else false
 */
export const isValidEmail = (email: string): boolean => {
  return regexPatterns.validEmail.test(email)
}

/**
 * Method to check if the passed pincode is valid. This validation is only for Indian pincodes
 * @param {string} zip               Zip to validate
 *
 * @return {boolean} true/false         Returns true if the zip is valid, else false
 */

export const isValidZip = (zip: string): boolean => {
  const regexPattern = /^[1-9][0-9]{5}$/
  return regexPattern.test(zip)
}

/**
 * Method to check if the passed gstin value is valid.
 * @param {string} gstin               GST to validate
 *
 * @return {boolean} true/false         Returns true if the gstin is valid, else false
 */

export const isValidGST = (gstin: string): boolean => {
  const regexPattern = regexPatterns.validGST
  return regexPattern.test(gstin)
}

/**
 * Method to check if the passed string contains any Emojis
 * @param {string} text               text to validate
 *
 * @return {boolean} true/false         Returns true if the emojis exists, else false
 */
export const containsEmojis = (text: string): boolean => {
  return !regexPatterns.hasEmoji.test(text)
}

/**
 * Method to check if the passed gstin value is valid.
 * @param {string} expiry               expiry date to validate
 *
 * @return {boolean} true/false         Returns true if the epriry date is valid, else false
 */

export const isValidExpiryDate = (expiryDate: string): boolean => {
  const expiry = expiryDate?.split('/')
  const d = new Date()
  const currentYear = d.getFullYear()
  const currentMonth = d.getMonth() + 1
  // get parts of the expiration date
  const year = parseInt(expiry[1], 10) + 2000
  const month = parseInt(expiry[0], 10)
  // compare the dates
  return !(year < currentYear || (year === currentYear && month < currentMonth))
}

export const isObjectEmptyOrNullFields = (obj: any) => {
  if (typeof obj === 'object' && obj !== null) {
    // eslint-disable-next-line eqeqeq
    return Object.keys(obj).filter(key => obj[key] != null).length === 0
  }
  return true
}

export const isIndianNumber = (value: string) => value.startsWith('+91')

export const isNumeric = (value: any) => !isNaN(value)

export const regexPatterns = {
  hasEmoji:
    //@ts-ignore
    /\p{RI}\p{RI}|\p{Emoji}(\p{EMod}+|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?(\u{200D}\p{Emoji}(\p{EMod}+|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?)+|\p{EPres}(\p{EMod}+|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?|\p{Emoji}(\p{EMod}+|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})/gu,
  validGST: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  validEmail:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  alphaNumeric: /[^A-Za-z0-9]/g,
  alphaNumericExcludesSpace: /[^A-Za-z0-9\s]/g,
  numeric: /[^\d.]$/g,
  upi: /[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}/,
  lettersOnly: /[^A-Za-z\s]/g,
  specialCharactersAndNumbersOnly: /^[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\d]*$/g,
  hasTouchScreen: /\b(BlackBerry|webOS|iPhone|IEMobile|Android|Windows Phone|iPad|iPod)\b/i,
  lettersAndPeriod: /[^A-Za-z.\s]/g,
  space: /[\s]/g,
  url: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  passwordPattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\S]{8,}$/g
}
