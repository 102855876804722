import React, { createContext, useEffect, useState } from 'react'
import { fetcher, staticOptions } from 'src/lib/core/api-client'
import useSWR, { mutate } from 'swr'
import { apiURI } from 'src/lib/utils/constants'
import { useAuth } from './AuthContext'

interface OrganisationContextProps {
  setOrganisationData: (data: any) => void
  organisationData: any
}

const OrganisationContext = createContext({} as OrganisationContextProps)

const OrganisationProvider = (props: any) => {
  const { loggedIn } = useAuth()
  const { data: orgResponse, isValidating } = useSWR(
    loggedIn ? [`/organization`, apiURI.AUTH_ENGINE] : null,
    ([url, baseURI]) => fetcher(url, baseURI),
    staticOptions
  )

  const [organisation, setOrganisation] = useState<any>()

  useEffect(() => {
    if (isValidating) return
    if (!orgResponse) return

    const { data } = orgResponse as any
    if (!data) return
    setOrganisation(data)
  }, [isValidating, orgResponse])

  const setOrganisationData = (data: any) => {
    setOrganisation(data)
  }

  const organisationContextValue: OrganisationContextProps = {
    setOrganisationData,
    organisationData: organisation
  }

  return <OrganisationContext.Provider value={organisationContextValue} {...props} />
}

const useOrganisation = () => React.useContext(OrganisationContext)

export { OrganisationProvider, useOrganisation }
