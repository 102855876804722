import React, { createContext, useState } from 'react'
import { UserType } from 'src/lib/types/user'
import { getParsedUserObj } from 'src/lib/utils/user'

interface UserContextType {
  setUserCtx: (data: any) => void
  user: UserType | undefined
  canAccess: (value: string, quantity: number) => boolean
  hasAllTabPermissions: (tabName: string) => boolean
}

const UserContext = createContext({} as UserContextType)

const UserProvider = (props: any) => {
  const [user, setUser] = useState<UserType>()

  const setUserCtx = (data: any) => {
    const parsedData = getParsedUserObj(data)
    setUser(parsedData)
  }

  const canAccess = (value: string, quantity = 0) => {
    if (!value) return false
    const hasPermission: any = user?.permissions?.find((permission: any) => permission.id === value)
    const limit = hasPermission?.metadata?.limit
    if (limit) {
      return Boolean(limit > quantity)
    }
    return Boolean(hasPermission)
  }

  const hasAllTabPermissions = (tabName: string) => {
    if (!tabName) return false

    const hasPermission = user?.permissions?.find((permission: any) => permission.id === tabName)

    if (!hasPermission) return false
    const totalTabPermissions = hasPermission.totalChildPermissions
    const availableTabPermissions = user?.permissions?.filter(
      (permission: any) => permission.parentPermissionId === tabName
    )
    return Boolean(totalTabPermissions === availableTabPermissions?.length)
  }

  const userContextValue: UserContextType = {
    setUserCtx,
    user,
    canAccess,
    hasAllTabPermissions
  }

  return <UserContext.Provider value={userContextValue} {...props} />
}

const useUser = () => React.useContext(UserContext)

export { UserProvider, useUser }
