// ** React Imports
import { ElementType, ReactNode } from 'react'

// ** Next Imports
import Link from 'next/link'
import { useRouter } from 'next/router'

// ** MUI Imports
import Chip from '@mui/material/Chip'
import ListItem from '@mui/material/ListItem'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton'

// ** Configs Import
import themeConfig from 'src/@core/configs/themeConfig'

// ** Types
import { NavLink } from 'src/@core/layouts/types'
import { Settings } from 'src/@core/context/settingsContext'

// ** Custom Components Imports
import UserIcon from 'src/layouts/components/UserIcon'

// ** Utils
import { handleURLQueries } from 'src/@core/layouts/utils'

interface Props {
  item: NavLink
  settings: Settings
  navVisible?: boolean
  toggleNavVisibility: () => void
}

const VerticalNavLink = ({ item, navVisible, toggleNavVisibility }: Props) => {
  // ** Hooks
  const router = useRouter()

  const IconTag: ReactNode = item.icon

  const isNavLinkActive = () => {
    const flowType = router.asPath.split(/[\/\?]/)
    if (flowType?.[2] === 'edit-c2p-discount' || flowType?.[2] === 'create-c2p-discount')
      return item.path === '/rto-suite'
    if (router.pathname === item.path || handleURLQueries(router, item.path)) {
      return true
    } else {
      return false
    }
  }

  // ** Styled Components
  const MenuNavLink = styled(ListItemButton)<
    ListItemButtonProps & { component?: ElementType; target?: '_blank' | undefined }
  >(({ theme }) => ({
    width: '100%',
    borderRadius: 16,
    border: isNavLinkActive() ? '2px solid #F0F0F0' : '2px solid transparent',
    backgroundImage: isNavLinkActive() ? `linear-gradient(270deg, #F0F0F0, #FAFAFA)` : '',
    color: theme.palette.text.primary,
    padding: theme.spacing(1.2, 0),
    transition: 'opacity .25s ease-in-out',
    '&.active, &.active:hover': {
      backgroundColor: `#E0E0E0`
    },
    '&.active, &.hover, .MuiTypography-root, &.active .MuiSvgIcon-root': {
      color: `text.header !important`
    }
  }))

  const MenuItemTextMetaWrapper = styled(Box)<BoxProps>({
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'opacity .25s ease-in-out',
    ...(themeConfig.menuTextTruncate && { overflow: 'hidden' })
  })

  return (
    <ListItem
      disablePadding
      className='nav-link'
      disabled={item.disabled || false}
      sx={{ marginLeft: '2rem', width: '208px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Link passHref href={item.path === undefined ? '/' : `${item.path}`}>
        <MenuNavLink
          component={'a' as any}
          className={isNavLinkActive() ? 'active' : ''}
          {...(item.openInNewTab ? { target: '_blank' } : null)}
          onClick={e => {
            if (item.path === undefined) {
              e.preventDefault()
              e.stopPropagation()
            }
            if (navVisible) {
              toggleNavVisibility()
            }
          }}
          sx={{
            padding: '0.4rem 0.6rem',
            ...(item.disabled ? { pointerEvents: 'none' } : { cursor: 'pointer' })
          }}
        >
          <ListItemIcon
            sx={{
              mr: 1.5,
              height: '16px',
              width: '16px',
              display: 'flex',
              alignItems: 'center',
              color: isNavLinkActive() ? 'text.header' : 'text.secondary',
              transition: 'margin .25s ease-in-out'
            }}
          >
            <UserIcon icon={IconTag} />
          </ListItemIcon>

          <MenuItemTextMetaWrapper>
            <Typography
              sx={{
                fontSize: '0.875rem',
                fontWeight: 500,
                color: isNavLinkActive() ? 'text.header' : 'text.secondary'
              }}
              {...(themeConfig.menuTextTruncate && { noWrap: true })}
            >
              {item.title}
            </Typography>
            {item.badgeContent ? (
              <Chip
                label={item.badgeContent}
                color={item.badgeColor || 'primary'}
                sx={{
                  height: 20,
                  fontWeight: 500,
                  marginLeft: 1.25,
                  '& .MuiChip-label': { px: 1.5 }
                }}
              />
            ) : null}
          </MenuItemTextMetaWrapper>
        </MenuNavLink>
      </Link>
    </ListItem>
  )
}

export default VerticalNavLink
